import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { B50 } from '@atlaskit/theme/colors';

import { FeatureCode } from '@confluence/feature-codes';

import { PageCardContext } from '../PageCardContext';
import {
	Card,
	ContentIcon,
	PageCardContainer,
	PageLink,
	RecommendationStrategy,
	Subtitle,
	Title,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCard = styled(Card)({
	'flex-basis': '100%',
	flex: '1',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCardWithHover = styled(StyledCard)({
	'&:hover': {
		backgroundColor: token('color.background.selected.hovered', B50),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridCard = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: 'calc(100% - 32px)',
	height: 'calc(100% - 28px)',
	maxHeight: '260px',
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} ${token('space.200', '16px')}`,
});

const Header = xcss({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	gap: 'space.100',
});

const HeaderSmallCard = xcss({
	marginBottom: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	display: 'flex',
	marginTop: token('space.050', '4px'),
});

const TitlesContainer = xcss({
	overflow: 'hidden',
	overflowWrap: 'break-word',
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.050',
});

const TitlesContainerSmallCard = xcss({
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtitleContainer = styled.div({
	display: 'flex',
	font: token('font.body.small'),
	zIndex: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div({
	flex: '1 0 auto',
	display: 'flex',
	alignItems: 'flex-end',
});

const FooterBigCard = xcss({
	marginTop: 'space.200',
});

const Footer = xcss({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const EndOfPageView: FC = () => {
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	const { smallCard } = useContext(PageCardContext);

	return (
		<PageCardContainer StyledCard={smallCard ? StyledCardWithHover : StyledCard}>
			<PageLink featureAttributionCode={FeatureCode.EOP_REC} />
			<GridCard data-testid="page-card-end-of-page-view">
				<Box xcss={[Header, ...(smallCard ? [HeaderSmallCard] : [])]}>
					<IconContainer>
						<ContentIcon />
					</IconContainer>
					<Box xcss={[TitlesContainer, ...(smallCard ? [TitlesContainerSmallCard] : [])]}>
						<TitleContainer>
							<Title />
						</TitleContainer>
						<SubtitleContainer>
							<Subtitle />
						</SubtitleContainer>
					</Box>
				</Box>
				<FooterContainer>
					<Box xcss={[Footer, ...(smallCard ? [] : [FooterBigCard])]}>
						<RecommendationStrategy />
					</Box>
				</FooterContainer>
			</GridCard>
		</PageCardContainer>
	);
};
